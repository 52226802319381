/* You can add global styles to this file, and also import other style files */
// html {
//     //scrollbar-width: auto;
//     overflow: scroll;
// }

// ::-webkit-scrollbar {
//     overflow-y: visible;
//     // height: 12px;
//     // width: 12px;
//     // background: #aaa;
// }

// // ::-webkit-scrollbar-thumb {
// //     background: #393812;
// //     -webkit-border-radius: 1ex;
// //     -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
// // }

// // ::-webkit-scrollbar-corner {
// //     background: #000;
// // }
